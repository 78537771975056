import { useProgramRequirements } from '@/app/hooks/useProgramRequirements'
import { Button, Text } from '@/components'
import ThemedDocumentRenderer from '@/components/ThemedDocumentRenderer/ThemedDocumentRenderer'
import { useAppDispatch, useAppSelector } from '@/hooks'
import { logEvent } from '@/logging'
import {
  selectInProgressEnrollment,
  setTosAccepted,
} from '@/reducers/inProgressUtilityProgramEnrollments'
import { searchableUtilityProgramsCollection } from '@/reducers/utilityPrograms'
import { AlertCircleIcon, CheckIcon } from 'lucide-react'
import { useState, useRef, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { P, match } from 'ts-pattern'
import { sanitizeProgramName } from '@/types/utilityProgram/utils'

const emptyRichDocument = [
  {
    type: 'paragraph',
    children: [
      {
        text: '',
      },
    ],
  },
]

function useViewModel() {
  const { id: utilityProgramId } = useParams<{ id: string }>()
  const { data: utilityPrograms } =
    searchableUtilityProgramsCollection.useFetch({
      path: `utility_programs/${utilityProgramId}`,
    })
  const utilityProgram = utilityPrograms?.[0]
  const { routeToNextStep } = useProgramRequirements(utilityProgram)
  const dispatch = useAppDispatch()
  const initialState = useAppSelector(
    selectInProgressEnrollment(+utilityProgramId),
  )
  const tosDocument = (
    JSON.stringify(
      utilityProgram.view_config_json?.tosDocument ?? emptyRichDocument,
    ) === JSON.stringify(emptyRichDocument)
      ? null
      : utilityProgram.view_config_json.tosDocument
  ) as Element[] | null
  const tosUrl =
    utilityProgram.view_config_json?.tosUrl?.length === 0
      ? undefined
      : utilityProgram.view_config_json.tosUrl
  const [isAccepted, setIsAccepted] = useState(
    initialState?.tosAccepted ?? false,
  )
  const contentRef = useRef<HTMLDivElement>(null)
  const [hasScrolledToBottom, setHasScrolledToBottom] = useState(false)

  const onScroll = () => {
    if (!contentRef.current) return

    const { scrollTop, scrollHeight, clientHeight } = contentRef.current
    const isAtBottom = scrollTop + clientHeight >= scrollHeight - 10

    if (scrollHeight <= clientHeight) {
      setHasScrolledToBottom(true)
      return
    }
    if (isAtBottom && !hasScrolledToBottom) {
      setHasScrolledToBottom(true)
    }
  }
  useEffect(() => {
    onScroll()
  }, [])

  const onAccept = () => {
    dispatch(
      setTosAccepted({ utilityProgramId: +utilityProgramId, accepted: true }),
    )
    if (initialState?.tosAccepted) {
      routeToNextStep()
    }
  }

  const content = match({
    tosDocument,
    tosUrl,
  })
    .with(
      { tosDocument: P.nonNullable, tosUrl: P.union(P.nullish, P.nonNullable) },
      ({ tosDocument }) => (
        <div className="flex flex-col justify-between bg-themed-grey-100 rounded-lg p-4 shadow-sm">
          <div
            ref={contentRef}
            onScroll={onScroll}
            className="overflow-y-auto h-[40svh] bg-themed-grey-100  border border-themed-gray-200 rounded mb-4"
          >
            <ThemedDocumentRenderer document={tosDocument} />
          </div>
          <AcceptTOS
            isAccepted={isAccepted}
            setIsAccepted={setIsAccepted}
            enabled={hasScrolledToBottom || initialState?.tosAccepted}
          />
        </div>
      ),
    )
    .with({ tosDocument: P.nullish, tosUrl: P.nonNullable }, () => (
      <div className="flex flex-col gap-8">
        <a href={tosUrl ?? ''}>
          <Text variant="link">
            View Terms of Service for {sanitizeProgramName(utilityProgram.name)}{' '}
            program
          </Text>
        </a>
        <AcceptTOS
          isAccepted={isAccepted}
          setIsAccepted={setIsAccepted}
          enabled={true}
        />
      </div>
    ))
    .otherwise(() => {
      logEvent(
        `missing either tosUrl or tosDocument from the CMS for utility program id: ${utilityProgramId}`,
      )
      return (
        <div className="rounded-lg p-4 bg-themed-red-100 flex flex-row gap-4">
          <div className="flex items-center justify-center">
            <AlertCircleIcon className="text-themed-error flex-none" />
          </div>
          <Text variant="body2" className="text-themed-error">
            Unable to load the Terms of Service for this utility program, please
            try again later.
          </Text>
        </div>
      )
    })

  return {
    content,
    isAccepted,
    utilityProgram,
    onAccept,
    hasScrolledToBottom,
  }
}

export default function ReviewTOS() {
  const { content, isAccepted, utilityProgram, onAccept } = useViewModel()
  return (
    <div className="h-full flex flex-col gap-4">
      <section>
        <Text variant="h3">Review Terms and Conditions</Text>
        <Text variant="body2">
          Please review our terms and conditions for the{' '}
          {sanitizeProgramName(utilityProgram.name)} program.
        </Text>
      </section>
      <section className="w-full h-full">{content}</section>
      <Button id={'accept-tos'} disabled={!isAccepted} onClick={onAccept}>
        Accept Terms and Conditions
      </Button>
    </div>
  )
}

function AcceptTOS({
  isAccepted,
  setIsAccepted,
  enabled = true,
}: {
  isAccepted: boolean
  setIsAccepted: (value: boolean) => void
  enabled?: boolean
}) {
  return (
    <div
      className="flex items-center gap-2"
      onClick={() => enabled && setIsAccepted(!isAccepted)}
    >
      <div
        className={`w-6 h-6 rounded-md focused:border-blue-200 border-gray-200 border-solid border-2 flex items-center justify-center ${
          enabled ? 'cursor-pointer' : 'cursor-not-allowed opacity-50'
        } ${isAccepted ? 'bg-themed-blue-500' : 'bg-themed-white'}`}
      >
        {isAccepted && <CheckIcon size={22} className="text-themed-white" />}
      </div>
      <Text
        variant="subheader"
        className={`text-themed-blue-500 text-xs ${
          enabled ? 'cursor-pointer' : 'cursor-not-allowed opacity-50'
        }`}
      >
        I have read the Terms and Conditions
      </Text>
    </div>
  )
}
